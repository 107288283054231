import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, Navigate } from "react-router-dom";
import SignIn from "./components/SignIn/signIn";
import BidConsoleDashboard from "./components/BidConsole/bidConsole";
import { API_ENDPOINTS } from "./constants/constants";

const fakeUser = {
  username: "admin@bidride.com",
  password: "admin",
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  /*  const handleLogin = async (username, password) => {
    try {
      const response = await fetch(`${API_BASE_URL}/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (response.ok) {
        setIsLoggedIn(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }; */

  const handleLogin = (username, password) => {
    if (username === fakeUser.username && password === fakeUser.password) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const ProtectedRoute = ({ element }) => {
    return isLoggedIn ? element : <Navigate to="/signin" replace />;
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<BidConsoleDashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
