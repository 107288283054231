import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Table } from "smart-webcomponents-react/table";
import "smart-webcomponents-react/source/styles/smart.default.css";
import "./bidConsole.css";

export function GetOrdersData(tData, rowscount) {
  console.log(tData, rowscount);
  const data = [];

  for (let i = 0; i < tData.length; i++) {
    const rowTest = {};

    rowTest.parentid = null;
    rowTest.id = i;

    rowTest.amenities = tData[i]["operator_bid_email"].amenities;
    rowTest.booking_id = tData[i]["operator_bid_email"].booking_id;
    rowTest.capacity = tData[i]["operator_bid_email"].capacity;
    rowTest.contact_email = tData[i]["operator_bid_email"].contact_email;
    rowTest.contact_name = tData[i]["operator_bid_email"].contact_name;
    rowTest.contact_phone = tData[i]["operator_bid_email"].contact_phone;
    rowTest.date = tData[i]["operator_bid_email"].booking_id;
    rowTest.date_of_job = tData[i]["operator_bid_email"].date_of_job;
    rowTest.hrs_duration = tData[i]["operator_bid_email"].hrs_duration;
    rowTest.in_house_price = tData[i]["operator_bid_email"].in_house_price;
    rowTest.insurance_limit = tData[i]["operator_bid_email"].insurance_limit;
    rowTest.notes = tData[i]["operator_bid_email"].notes;
    rowTest.num_of_ops = tData[i]["operator_bid_email"].num_of_ops;
    rowTest.pickup = tData[i]["operator_bid_email"].pickup;
    rowTest.price = tData[i]["operator_bid_email"].price;
    rowTest.target_price_75_percent = tData[i]["operator_bid_email"].target_price_75_percent;
    rowTest.vehicle_make_model = tData[i]["operator_bid_email"].vehicle_make_model;
    rowTest.vehicle_year = tData[i]["operator_bid_email"].vehicle_year;
    rowTest.qualifiedBid = 0;
    data.push(rowTest);
    const subRowsCount = tData[i]["bids"].length;
    let a = 0;
    const qualifiedBidTest = [];

    for (let k = 0; k < subRowsCount; k++) {
      const cut = parseFloat(tData[i]["bids"][k].price.substring(1, tData[i]["bids"][k].price.length)).toFixed(2);
      qualifiedBidTest.push(cut);
    }
    let qulaifiedBidprice = Math.min(...qualifiedBidTest);
    for (let j = 0; j < subRowsCount; j++) {
      const subRow = {};
      subRow.parentid = i;
      subRow.id = "" + i + "." + (1 + j);
      subRow.amenities = tData[i]["bids"][j].amenities;
      subRow.booking_id = tData[i]["bids"][j].booking_id;
      subRow.capacity = tData[i]["bids"][j].capacity;
      subRow.contact_email = tData[i]["bids"][j].contact_email;
      subRow.contact_name = tData[i]["bids"][j].contact_name;
      subRow.contact_phone = tData[i]["bids"][j].contact_phone;
      subRow.date = tData[i]["bids"][j].date;
      subRow.date_of_job = tData[i]["bids"][j].date_of_job;
      subRow.hrs_duration = tData[i]["bids"][j].hrs_duration;
      subRow.in_house_price = tData[i]["bids"][j].in_house_price;
      subRow.insurance_limit = tData[i]["bids"][j].insurance_limit;
      subRow.notes = tData[i]["bids"][j].notes;
      subRow.num_of_ops = tData[i]["bids"][j].num_of_ops;
      subRow.pickup = tData[i]["bids"][j].pickup;
      subRow.price = tData[i]["bids"][j].price;
      subRow.target_price_75_percent = tData[i]["bids"][j].target_price_75_percent;
      subRow.vehicle_make_model = tData[i]["bids"][j].vehicle_make_model;
      subRow.vehicle_year = tData[i]["bids"][j].vehicle_year;
      if (Number(tData[i]["bids"][j].price.substring(1, tData[i]["bids"][j].price.length)) == qulaifiedBidprice) {
        subRow.qualifiedBid = tData[i]["bids"][j].price;
      } else {
        subRow.qualifiedBid = 0;
      }
      //  console.log(subRow.qualifiedBid);
      /* setBid(subRow.price);
      if (bid <= previousValue) {
        setBid(subRow.price);
      }
      console.log(bid); */

      data.push(subRow);
    }

    /*  const subRowsCount =  tData[i]["bids"].length;
    let t = 0;
    for (let j = 0; j < subRowsCount; j++) {

    }
 */
  }

  return data;
}

const BidConsoleDashboard = () => {
  const [apiData, setApiData] = useState([]);
  // const { getOpsList, opsList, loading, data, error } = useApi();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiCall = await fetch("https://www.bidmyride.com/api/operator_bids_email_report?state=active");
        let res = await apiCall.json();
        setApiData(res);
        GetOrdersData(res, res.length || 50);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  

  const conditionalFormatting = [
    {
      column: "price",
      condition: "lessThan",
      firstValue: 650,
      fontSize: "10px",
      text: "#FFFFFF",
      highlight: "#63BE7B",
    },
  ];
  const conditionalFormattingButton = true;
  const onShowNameClicked = () => {};

  const dataSource = new window.Smart.DataAdapter({
    dataSource: GetOrdersData(apiData, apiData.length || 50),
    id: "id",
    keyDataField: "id",
    parentDataField: "parentid",
    dataFields: [
      "date:string",
      "booking_id:string",
      "date_of_job:string",
      "amenities:string",
      "capacity:string",
      "contact_email:string",
      "contact_name:string",
      "contact_phone:string",
      "rebid:string",
      "hrs_duration:string",
      "in_house_price:string",
      "insurance_limit:string",
      "notes:string",
      "num_of_ops:string",
      "pickup:string",
      "price:string",
      "target_price_75_percent:string",
      "vehicle_make_model:string",
      "vehicle_year:string",
      "qualifiedBid:number",
    ],
  });

  const keyboardNavigation = true;

  const filtering = true;

  const sortMode = "one";

  const paging = true;
  const editing = true;
  const columnMenu = true;
  const columnReorder = true;
  const selection = true;

  const editMode = "cell";
  const columnResize = true;
  const columnResizeFeedback = true;
  const tooltip = true;
  const freezeHeader = true;

  const columns = [
    {
      label: "Date",
      dataField: "date",
      freeze: "near",
      width: 250,
      allowEdit: false,
    },
    {
      label: "Booking ID",
      dataField: "booking_id",
      width: 120,
      allowEdit: false,

      //dataField: "name",
    },
    {
      label: "Date of Job",
      dataField: "date_of_job",
      width: 120,
      allowEdit: false,
    },
    {
      label: "In-House Price",
      dataField: "in_house_price",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Target Price (75%)",
      dataField: "target_price_75_percent",
      width: 120,
      allowEdit: false,
    },
    {
      label: "BidPrice",
      dataField: "price",
      width: 120,
      allowEdit: false,
      dataType: "number",
      formatFunction(settings) {
        if (settings.data.qualifiedBid != 0) {
          settings.template = `<span class="badge badge-info">${settings.data.price}</span>`;
        }
      },
    },
    {
      label: "Rebid",
      dataField: "rebid",
      width: 120,
      allowEdit: false,
      formatFunction(settings) {
        if (settings.data.parentid == null) {
          settings.template = `<span class="badge badge-info">Rebid</span>`;
        } else {
          settings.template = "";
        }
      },
    },
    {
      label: "Capacity",
      dataField: "capacity",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Vehicle Year",
      dataField: "vehicle_year",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Vehicle Make Model",
      dataField: "vehicle_make_model",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Insurance Limit",
      dataField: "insurance_limit",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Amenities",
      dataField: "amenities",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Contact Name",
      dataField: "contact_name",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Contact Email",
      dataField: "contact_email",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Contact Phone",
      dataField: "contact_phone",
      width: 120,
      allowEdit: false,
    },
    {
      label: "# of Ops",
      dataField: "num_of_ops",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Pickup",
      dataField: "pickup",
      width: 300,
      allowEdit: false,
    },
    {
      label: "Hrs/Duration",
      dataField: "hrs_duration",
      width: 120,
      allowEdit: false,
    },
    {
      label: "Deleena",
      dataField: "notes",
      width: 120,
      allowEdit: true,
    },
  ];

  return (
    <div className="console-container">
      <div className="header">
        <div>
          <span className="header-text-left-align">Admin Console</span>
        </div>
        <div>
          <span className="header-text-right-align">BidMyRide</span>
        </div>
      </div>
      <div
        style={{
          width: "98%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          background: "none",
          height: "600px",
          overflowY: "auto"
          // height: "calc(100% - 56px)",
        }}
      >
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
          integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
          crossOrigin="anonymous"
        ></link>

        <Table
          id="table"
          className="table-responsive table-hover table-bordered"
          dataSource={dataSource}
          keyboardNavigation={keyboardNavigation}
          // paging={paging}
          columns={columns}
          filtering={filtering}
          sortMode={sortMode}
          editing={editing}
          editMode={editMode}
          //  selection={selection}
          columnMenu={columnMenu}
          columnResize={columnResize}
          columnResizeFeedback={columnResizeFeedback}
          // tooltip={tooltip}
          columnReorder={columnReorder}
          conditionalFormatting={conditionalFormatting}
          conditionalFormattingButton={conditionalFormattingButton}
          freezeHeader={freezeHeader}
          autoHeight={true}
        ></Table>
      </div>
    </div>
  );
};

export default BidConsoleDashboard;
